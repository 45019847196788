import React, { useRef, useState } from 'react';

import _ from 'lodash';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import BulkSkuTypeSelectionDropdown from 'components/BulkComponents/BulkSkuTypeSelectionDropdown';
import CharitySelect from 'components/CharitySelect/CharitySelect';

import { Icon as MDIcon } from '@mdi/react';

import { mdiWalk, mdiSack, mdiExitRun, mdiCashEdit } from '@mdi/js';

import * as colors from '@material-ui/core/colors';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTheme } from '@material-ui/core/styles';
import { mdiAccountSearch } from '@mdi/js';
import { DialogContentText, Typography, FormControlLabel, FormControl, Switch, Collapse } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import { getQuickDropName, getWalkInName } from 'utils/misc';
import useWindowSize from 'utils/hooks/useWindowSize';

const BULK_TYPE_NAMES = {
    inhouse: getQuickDropName('en'),
    adjustment: 'Adjustment',
    'walk-in': getWalkInName('en'),
    lostandfound: 'Lost and Found'
};

const BULK_TYPE_DESCRIPTIONS = {
    inhouse: `Use this for customers who have left their bags and didn't create a drop off order themselves. 
    If a customer is having issues with their location not registering have them enter the override code above. 
    Create the order here if that doesn't work.`,
    adjustment:
        'Use this to credit customers for lost items or incorrect counts if they have already redeemed their last order.',
    'walk-in': `Only enter in their customer information if they
    have an existing account, creation of a new account can be done after the order is counted.`,
    lostandfound: ''
};

function BulkCreateDialog(props) {
    const {
        theme,
        open,
        skuType,
        bulkType,
        skus,
        error,
        inProgress,
        customerID,
        commodityColors,
        donateQuickdrop,
        donate,
        charityPreferred,
        subdivisionPreferred,
        allCharities,
        inhouseDisabled,
        adjustmentsDisabled,
        walkinDisabled,
        onCreateBulk,
        onCustomerIDChange,
        onClose,
        onSkuTypeChange,
        onBulkTypeChange,
        onDonateQuickdrop,
        onCharityPreferred,
        onSubdivisionPreferred,
        onSetDonation,
        lostAndFoundBulks,
        lostAndFoundBulksLoading,
        onViewLostAndFound,
        lostAndFoundBulkType,
        handleLAFBulkTypeChange,
        onSnackbar,
        handleOpenCreateBulkDialog,
        skuTypeFilter
    } = props;

    const [width, height] = useWindowSize();
    let mobileMode = width <= 600;
    let showKeyboard =
        !mobileMode && JSON.parse(localStorage.getItem('hideKeyboard')) !== true && bulkType !== 'lostandfound';

    let disableSubmitting =
        (bulkType === 'adjustment' && _.isEmpty(customerID)) ||
        (bulkType === 'inhouse' && _.isEmpty(customerID) && _.isNil(charityPreferred)) ||
        (_.isNil(charityPreferred) && donateQuickdrop);

    const handleKeyPress = e => {
        if (!disableSubmitting && e.key === 'Enter' && !_.isEmpty(customerID)) {
            onCreateBulk();
        }
    };

    const [layout, setLayout] = useState('default');
    const keyboard = useRef();

    const handleShift = () => {
        const newLayoutName = layout === 'default' ? 'shift' : 'default';
        setLayout(newLayoutName);
    };
    const handleEnter = () => {
        if (!disableSubmitting) {
            onCreateBulk();
        }
    };

    const onKeyPress = button => {
        if (button === '{shift}' || button === '{lock}') handleShift();
        if (button === '{enter}') handleEnter();
    };

    const onKeyReleased = button => {
        if (button === '{enter}' && disableSubmitting) {
            if (_.isEmpty(customerID)) {
                setTimeout(() => {
                    onSnackbar('Invalid customer ID', 'error');
                }, 10);
            } else if (_.isNil(charityPreferred)) {
                setTimeout(() => {
                    onSnackbar('Invalid customer ID', 'error');
                }, 10);
            }
        }
    };

    return (
        <Dialog open={open} onClose={onClose} data-cy="bulk-search-dialog">
            <DialogTitlePrimary>New {_.get(BULK_TYPE_NAMES, bulkType, '')} Order</DialogTitlePrimary>
            {_.isNil(bulkType) ? (
                <DialogContent style={{ marginTop: theme.spacing.unit * 3 }}>
                    <Typography style={{ marginBottom: theme.spacing.unit * 2 }} variant="h6">
                        Select the type of order to create:
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {!inhouseDisabled && (
                            <Button
                                data-cy="bulk-counter-create-quickdrop-btn"
                                color="primary"
                                variant="contained"
                                style={{
                                    marginBottom: theme.spacing.unit * 2
                                }}
                                onClick={() => onBulkTypeChange('inhouse')}
                            >
                                <span style={{ marginRight: theme.spacing.unit }}>
                                    <MDIcon path={mdiWalk} size={1} color="white" />
                                </span>
                                {getQuickDropName()}
                            </Button>
                        )}
                        {!walkinDisabled && (
                            <Button
                                data-cy="bulk-counter-create-walkin-btn"
                                color="primary"
                                variant="contained"
                                style={{
                                    marginBottom: theme.spacing.unit * 2
                                }}
                                onClick={() => onBulkTypeChange('walk-in')}
                            >
                                <span style={{ marginRight: theme.spacing.unit }}>
                                    <MDIcon path={mdiExitRun} size={1} color="white" />
                                </span>
                                {process.env.REACT_APP_REGION_EXT === 'CON' ? 'Walk In' : getWalkInName('en')}
                            </Button>
                        )}
                        {!adjustmentsDisabled && (
                            <Button
                                data-cy="bulk-counter-create-adjustment-btn"
                                color="primary"
                                variant="contained"
                                style={{
                                    marginBottom: theme.spacing.unit * 2
                                }}
                                onClick={() => onBulkTypeChange('adjustment')}
                            >
                                <span style={{ marginRight: theme.spacing.unit }}>
                                    <MDIcon path={mdiCashEdit} size={1} color="white" />
                                </span>
                                Adjustment
                            </Button>
                        )}
                        <Button
                            data-cy="lost-and-found-btn"
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                handleOpenCreateBulkDialog(skuTypeFilter, 'lostandfound');
                            }}
                        >
                            Lost and Found
                        </Button>
                    </div>
                </DialogContent>
            ) : (
                <>
                    <DialogContent style={{ marginTop: theme.spacing.unit * 3 }}>
                        <DialogContentText style={{ marginBottom: theme.spacing.unit * 2 }}>
                            {_.get(BULK_TYPE_DESCRIPTIONS, bulkType, '')}
                        </DialogContentText>
                        <Grid container spacing={theme.spacing.unit} style={{ justifyContent: 'center' }}>
                            {bulkType !== 'lostandfound' && (
                                <Grid item sm={6}>
                                    <TextField
                                        data-cy="bulk-create-customerid-search"
                                        id="bulk-label"
                                        label="Customer ID or Email"
                                        value={customerID}
                                        margin="normal"
                                        variant="outlined"
                                        autoComplete="off"
                                        fullWidth
                                        helperText={!_.isNil(error) ? error : null}
                                        error={!_.isNil(error)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MDIcon path={mdiAccountSearch} size={1} color={colors.blue[500]} />
                                                </InputAdornment>
                                            )
                                        }}
                                        style={{ marginTop: 0 }}
                                        onChange={e => {
                                            onCustomerIDChange(e);
                                            console.log('keyboard', keyboard);
                                            if (showKeyboard && !_.isNil(keyboard.current)) {
                                                keyboard.current.setInput(e.target.value);
                                            }
                                        }}
                                        onKeyPress={handleKeyPress}
                                    />
                                </Grid>
                            )}
                            {bulkType === 'lostandfound' && (
                                <Grid item sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel variant="outlined">{'Type'}</InputLabel>
                                        <Select
                                            data-cy="bulk-type-selection-dropdown-lostandfound"
                                            value={lostAndFoundBulkType}
                                            onChange={handleLAFBulkTypeChange}
                                            // disabled={disabled}
                                            inputProps={{
                                                name: 'commodity-filter',
                                                id: 'commodity-filter'
                                            }}
                                            input={
                                                <OutlinedInput labelWidth={36} name="age" id="outlined-age-simple" />
                                            }
                                            style={{
                                                minWidth: 132
                                            }}
                                        >
                                            <MenuItem value="mobile">
                                                <em>Mobile</em>
                                            </MenuItem>
                                            <MenuItem value="inhouse">
                                                <em>{getQuickDropName('en')}</em>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {showKeyboard && (
                                <Grid item sm={3} xs={3} style={{}}>
                                    <FormControl fullWidth>
                                        <Button
                                            data-cy="bulk-counter-count-bulk"
                                            color="primary"
                                            variant="contained"
                                            disabled={_.isEmpty(customerID)}
                                            style={{
                                                height: 56
                                            }}
                                            onClick={() => {
                                                onCustomerIDChange({ target: { value: '' } });
                                                if (showKeyboard && !_.isNil(keyboard.current)) {
                                                    keyboard.current.setInput('');
                                                }
                                            }}
                                        >
                                            clear
                                        </Button>
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item sm={showKeyboard ? 3 : 6}>
                                <BulkSkuTypeSelectionDropdown
                                    commodityColors={commodityColors}
                                    skus={skus}
                                    showAll={false}
                                    helperText={bulkType === 'lostandfound' ? 'Material' : 'Type'}
                                    fullWidth={true}
                                    typeFilter={skuType}
                                    handleChange={onSkuTypeChange}
                                    labelCustomWidth={bulkType === 'lostandfound' ? 60 : 36}
                                />
                            </Grid>
                            {(bulkType === 'inhouse' || bulkType === 'walk-in') && (
                                <>
                                    <Grid item sm={12}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    data-cy="bulk-create-dialog-donate"
                                                    checked={donateQuickdrop}
                                                    onChange={e => onDonateQuickdrop(e.target.checked)}
                                                    color="primary"
                                                />
                                            }
                                            label={`User wants to donate`}
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Collapse in={donateQuickdrop}>
                                            <CharitySelect
                                                style={{
                                                    marginTop: theme.spacing.unit * 2,
                                                    marginBottom: theme.spacing.unit * 2
                                                }}
                                                hideTaxReciptAndSubdivision
                                                donate={donate}
                                                charities={allCharities}
                                                charityPreferred={charityPreferred}
                                                subdivisionPreferred={subdivisionPreferred}
                                                onCharityPreferred={onCharityPreferred}
                                                onChange={onSubdivisionPreferred}
                                                onSetDonation={onSetDonation}
                                                TextFieldProps={{
                                                    helperText: ``
                                                }}
                                            />
                                        </Collapse>
                                    </Grid>
                                </>
                            )}

                            {showKeyboard && (
                                <Grid item sm={12} xs={12}>
                                    <Keyboard
                                        keyboardRef={r => (keyboard.current = r)}
                                        layoutName={layout}
                                        onChange={input => {
                                            let e = { target: { value: input } };
                                            onCustomerIDChange(e);
                                        }}
                                        onKeyPress={onKeyPress}
                                        onKeyReleased={onKeyReleased}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {bulkType === 'lostandfound' && (
                            <Button
                                data-cy="view-lostandfound-bulks"
                                color="primary"
                                disabled={_.isEmpty(lostAndFoundBulks)}
                                style={{ margin: theme.spacing.unit }}
                                onClick={onViewLostAndFound}
                            >
                                view all lost and found{' '}
                                {lostAndFoundBulksLoading && (
                                    <CircularProgress
                                        style={{ marginLeft: theme.spacing.unit, color: 'grey' }}
                                        size={16}
                                    />
                                )}
                            </Button>
                        )}
                        <Button
                            data-cy="bulk-counter-count-bulk"
                            color="primary"
                            disabled={disableSubmitting}
                            style={{ margin: theme.spacing.unit }}
                            onClick={onCreateBulk}
                        >
                            {inProgress ? (
                                <CircularProgress size={16} />
                            ) : (
                                `Create ${_.get(BULK_TYPE_NAMES, bulkType, '')} Order`
                            )}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}

export default withTheme()(BulkCreateDialog);
